import React, { useCallback } from 'react';
import utils from 'utils';
import RESERVATIONS from 'constants/reservations';
import PropTypes from 'prop-types';
import THEMES from 'constants/themes';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
import TextField from 'components/Form/TextField';
import PhoneNumberField from 'components/Form/PhoneNumberField';
import Checkbox from 'components/Form/Checkbox';
import ToolTip from 'components/ToolTip';
import SmsConsent from 'components/SMSConsent/SMSConsent';
import ImplicitSMSConsent from 'components/SMSConsent/ImplicitSMSConsent';
import FieldGroupCard from '../FieldGroupCard';
import CheckInCard from '../CheckInCard/CheckInCard';

const { AUTO_COMMS_CONSENT_OPT_IN } = RESERVATIONS;
/**
 * DriverDetailsCard component shows driver details in multiple configurations:
 *   • empty inputs when no driver profile
 *   • filled in driver_details when true modify or Alamo Insider
 *   • additional member number details when Alamo Insider
 *
 * @param {object}    props - React Props
 * @param {func}      props.curriedUpdateCommitState - Curried function to update the commit state
 * @param {boolean}   props.resHasLimitedInventoryItemOrVehicle - TODO
 * @param {boolean}   props.isTrueModify - true modify flag
 * @param {string}    props.reservationFullName - full name from reservation.driver_info object
 * @param {string}    props.reservationEmail - email from reservation.driver_info object
 * @param {string}    props.reservationPhone - full name from reservation.driver_info object
 * @param {boolean}   props.isEuropeanUnionCountry - european_union_country flag from res object
 * @param {object}    props.authUserProfile - authenticated user loyalty profile object
 * @param {boolean}   props.isAuth - true if profileData exists indicating user is authenticated
 * @param {boolean}   props.marketingMessageIndicator - marketing message indicator from contract details
 * @param {boolean}   props.isLoggedIn - loggedIn user state
 * @return {JSX}      DriverDetailsCard component
 */
const DriverDetailsCard = (props) => {
  const emailSignUpChecked = utils.config.isUnitedStatesDomain();
  const {
    curriedUpdateCommitState,
    isTrueModify,
    reservationFullName,
    reservationEmail,
    reservationPhone,
    resHasLimitedInventoryItemOrVehicle,
    isEuropeanUnionCountry,
    authUserProfile,
    isAuth,
    isAfterHoursPickup,
    isLoggedIn,
    onlineCheckInStatus,
    showInResOCIActivatedProfile,
    form,
    isSelectedInResOCISaveTime,
    initialValues,
    setupInitialFormState,
    reservationFirstName,
    reservationLastName,
    isEditingProfile,
    isPayLater,
    setAutoCommsConsent,
    getSmsOptInEnabled,
    showSMSConsent,
    showEuImpliedConsent,
    handleShowFormError,
    isEmailUpdateRequired,
    showUpdatedExpeditedEmailValue,
    showEmailSpecials,
  } = props;

  const { memberNumber } = authUserProfile || {};

  const shouldShowPhoneField =
    resHasLimitedInventoryItemOrVehicle || isEuropeanUnionCountry || isAfterHoursPickup || authUserProfile || !isAuth;

  const updateCommitStatePhoneNumber = (data) => {
    if (data.phoneNumber || data.country) {
      curriedUpdateCommitState('phone')({
        phone_number: data.phoneNumber,
        country_code: data.country?.country_code,
      });
    }
  };

  const updateCommitStateRequestEmailPromotions = (e, input, isChecked) => {
    curriedUpdateCommitState('request_email_promotions')(isChecked);
  };

  const smsOptInUpdate = (value) => {
    setAutoCommsConsent(AUTO_COMMS_CONSENT_OPT_IN, value === 'true');
  };

  const cookiesPolicyLink = (
    <a
      key='cookiesPolicyLink'
      className='link link--external'
      target='_blank'
      rel='noopener noreferrer'
      href={utils.config.getUrl(WINDOW_OBJECT_KEYS.COOKIE_POLICY)}
    >
      {utils.i18n('review_drive_details_footer_legal_cookies_policy')}
    </a>
  );

  const smsTermsLink = (
    <a
      key='smsTermsLink'
      className='link link--external'
      target='_blank'
      rel='noopener noreferrer'
      href={utils.config.getUrl(WINDOW_OBJECT_KEYS.SMS_TERMS)}
    >
      {utils.i18n('sms_opt_in-legal_sms_terms')}
    </a>
  );

  const privacyPolicyLink = (
    <a
      key='privacyPolicyLink'
      className='link link--external'
      target='_blank'
      rel='noopener noreferrer'
      href={utils.config.getUrl(WINDOW_OBJECT_KEYS.PRIVACY_POLICY)}
    >
      {utils.i18n('review_drive_details_footer_legal_privacy_policy')}
    </a>
  );

  const getTooltipName = useCallback(() => {
    if (isTrueModify) {
      return 'true_modify_review_section_drive_details_tooltip';
    }
    if (isAuth) {
      return 'alamo_insider_name_change_tooltip';
    }
    return 'unauth_name_change_tooltip';
  }, [isTrueModify, isAuth]);

  const renderConsentToText = !isTrueModify && showSMSConsent;

  return (
    <FieldGroupCard title={utils.i18n('review_driver_details_title')}>
      {reservationFullName ? (
        <>
          <div className='driver-details__name-label'>
            {utils.i18n('true_modify_review_section_drive_details_name')}
            <ToolTip placement='top-start'>
              <span>{utils.i18n(getTooltipName())}</span>
            </ToolTip>
          </div>
          <div className='driver-details__static-driver-details'>
            <span>{reservationFullName}</span>
            {memberNumber && (
              <span>
                {utils.i18n('alamo_insider_number_label')}: {memberNumber}
              </span>
            )}
          </div>
        </>
      ) : (
        <div className='review__section-card__field-row'>
          <TextField
            name='driver_info.first_name'
            label={utils.i18n('review_drive_details_first_name_input_label')}
            onChange={curriedUpdateCommitState('first_name')}
            fill
            required
            autoComplete='given-name'
          />
          <TextField
            name='driver_info.last_name'
            label={utils.i18n('review_drive_details_last_name_input_label')}
            onChange={curriedUpdateCommitState('last_name')}
            fill
            required
            autoComplete='family-name'
          />
        </div>
      )}

      <div className='review__section-card__field-row'>
        {shouldShowPhoneField && (
          <PhoneNumberField
            name='driver_info.phone_number'
            label={utils.i18n('review_drive_details_phone_number_input_label')}
            onChange={updateCommitStatePhoneNumber}
            fill
            required
            validations={[utils.fieldValidation.phone, utils.fieldValidation.checkPhoneCode]}
            autoComplete='tel'
            initialValueButton={reservationPhone}
            inputType='phone'
          />
        )}

        <TextField
          name='driver_info.email_address'
          label={utils.i18n('review_drive_details_email_input_label')}
          onChange={curriedUpdateCommitState('email_address')}
          fill
          required
          validations={[utils.fieldValidation.email]}
          autoComplete='email'
          initialValueButton={showUpdatedExpeditedEmailValue || reservationEmail}
          forceFocus={isEmailUpdateRequired}
        />
      </div>
      {renderConsentToText && (
        <div className='review__section-sms__opt-in-row'>
          {showEuImpliedConsent && <ImplicitSMSConsent privacyPolicyLink={privacyPolicyLink} />}
          {getSmsOptInEnabled && (
            <SmsConsent
              smsOptInUpdate={smsOptInUpdate}
              name='auto_comms_consent_opt_in'
              privacyPolicyLink={privacyPolicyLink}
              smsTermsLink={smsTermsLink}
            />
          )}
        </div>
      )}

      {showEmailSpecials && (
        <>
          <div className='review__section-card__field-row'>
            <Checkbox
              initialValue={emailSignUpChecked}
              name='driver_info.request_email_promotions'
              label={utils.i18n('review_form_newsletter_checkbox_label')}
              theme={THEMES.LIGHT}
              onChange={updateCommitStateRequestEmailPromotions}
            />
          </div>
          <p className='review__section-card__footer-legals'>
            {utils.i18n('review_drive_details_footer_legal', [privacyPolicyLink, cookiesPolicyLink], { jsx: true })}
          </p>
        </>
      )}
      {isLoggedIn && showInResOCIActivatedProfile && (
        <CheckInCard
          onlineCheckInStatus={onlineCheckInStatus}
          showInResOCIActivatedProfile={showInResOCIActivatedProfile}
          form={form}
          isSelectedInResOCISaveTime={isSelectedInResOCISaveTime}
          initialValues={initialValues}
          setupInitialFormState={setupInitialFormState}
          reservationFirstName={reservationFirstName}
          reservationLastName={reservationLastName}
          isLoggedIn={isLoggedIn}
          isEditingProfile={isEditingProfile}
          isPayLater={isPayLater}
          handleShowFormError={handleShowFormError}
          isEmailUpdateRequired={isEmailUpdateRequired}
          curriedUpdateCommitState={curriedUpdateCommitState}
        />
      )}
    </FieldGroupCard>
  );
};

DriverDetailsCard.propTypes = {
  // Parent props
  curriedUpdateCommitState: PropTypes.func.isRequired,
  resHasLimitedInventoryItemOrVehicle: PropTypes.bool,
  onlineCheckInStatus: PropTypes.object,
  showInResOCIActivatedProfile: PropTypes.bool,
  setupInitialFormState: PropTypes.func,
  handleShowFormError: PropTypes.func,

  // Container props
  isTrueModify: PropTypes.bool,
  reservationFullName: PropTypes.string,
  reservationEmail: PropTypes.string,
  isEuropeanUnionCountry: PropTypes.bool,
  reservationPhone: PropTypes.string,
  authUserProfile: PropTypes.object,
  isAuth: PropTypes.bool,
  marketingMessageIndicator: PropTypes.bool,
  isAfterHoursPickup: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  showUpdatedExpeditedEmailValue: PropTypes.bool,
};

export default DriverDetailsCard;
